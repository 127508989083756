// Entry point for the build script in package.json
import { createRoot } from 'react-dom/client'
import { createElement } from 'react'
import initializeSentry from './initialize_sentry';
import CheckoutComponent from './components/checkout_component';
import ShippingComponent from './components/shipping_component';
import ShippingAccountModal from './components/shipping_account_modal'
import ProductCatalogComponent from './components/product_catalog_component';
import AdminManageShippingAccountsComponent from './components/admin_manage_shipping_accounts'

const components = {
  'CheckoutComponent': CheckoutComponent,
  'ShippingComponent': ShippingComponent,
  'ShippingAccountModal': ShippingAccountModal,
  'ProductCatalogComponent': ProductCatalogComponent,
  'AdminManageShippingAccountsComponent': AdminManageShippingAccountsComponent
}

function renderComponent(elementId, componentName) {
var element = document.getElementById(elementId);
  if (element) {
    const root = createRoot(element);
    const props = JSON.parse(element.dataset?.props ?? "{}");
    // render the component
    root.render(createElement(components[componentName], props));
  }
}

window.onload = async function () {
  initializeSentry();
  // renderComponent is used in ApplicationHelper.render_react_from_vite
  window.renderComponent = renderComponent;
  window.dispatchEvent(new Event("renderComponentDefined"));
};