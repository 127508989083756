import React, { Suspense } from 'react';
import Logo from './logo'

const ShippingContainer = React.lazy(async () => {
  return await import("shipping/ShippingContainer");
});

const ShippingComponent = (props) => {
  function resultMessage(message) {
    console.log(message);
  }

  const handleSave = async (shippingAddress, shippingOption, shippingAccount, deliveryInstructions) => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
      const response = await fetch("/checkout/save_shipping_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          shippingAddress: shippingAddress,
          shippingOption: shippingOption,
          shippingAccount: shippingAccount,
          deliveryInstructions: deliveryInstructions,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        if (props.checkoutType != 'paypal') {
          window.location.href = data.redirect;
        }
        return true
      } else {
        console.error(await response.json());
        return false
      }
    } catch (error) {
      console.error(error);
      resultMessage(`Could not save shipping information...${error}`);
      return false
    }
  }

  const paypalCallbacks = {
    createOrder: async () => {
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      try {
        const response = await fetch("/paypal/orders", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        });
        const orderData = await response.json();
        if (orderData.id) {
          return orderData.id;
        } else {
          const errorDetail = orderData?.details?.[0];
          const errorMessage = errorDetail
            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
            : JSON.stringify(orderData);

          throw new Error(errorMessage);
        }
      } catch (error) {
        console.error(error);
        resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
      }
    },
    onApprove: async (data, actions) => {
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      console.log(data)
      try {
        const response = await fetch(`/paypal/capture/${data.orderID}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        });

        const orderData = await response.json();
        // Three cases to handle:
        //   (1) Successful transaction -> Show confirmation or thank you message
        //   (2) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (3) Other non-recoverable errors -> Show a failure message
        const errorDetail = orderData?.response?.details?.[0];
        if (orderData.success) {
          console.log(orderData)
          window.location.href = orderData.redirect
        } else if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
          // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
          return actions.restart();
        } else if (errorDetail) {
          // (2) Other non-recoverable errors -> Show a failure message
          throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
        } else {
          throw new Error(JSON.stringify(orderData));
        }
      } catch (error) {
        console.error(error);
        resultMessage(
          `Sorry, your transaction could not be processed...<br><br>${error}`,
        );
      }
    },
  }
  return (
    <div style={{ minHeight: 'calc(100vh - 397px)', textAlign: 'center', paddingTop: '40px' }}>
      <Suspense fallback={'Loading...'}>
        <ShippingContainer
          logo={<Logo />}
          paypalDetails={{
            callbacks: paypalCallbacks,
            clientId: 'AT0gn0-Y511Pf7G6RGJHhRP1fIeZSBJJjhpMLd5LfHfU80NH65yamnphYP0fHoIJfwWfVQWjbCA47rLq'
          }}
          onSave={handleSave}
          {...props}
        />
      </Suspense>
    </div>
  )
}

export default ShippingComponent